import React, { useEffect, useState, useRef } from 'react';
import {
  HeroBannerComponent,
  HeroBannerCTAButton,
  HeroBannerDescription,
  HeroBannerHeading,
  HeroBannerImage,
  HeroBannerInteractiveBody,
  HeroBannerInteractivePosition,
  HeroBannerStoreImage,
  ViewMoreSpan,
} from './HeroBanner1.styles';
import { Button as HeroBannerButton } from '../../AtomicComponents';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { getRoute, PATH } from '@/utils/routes';
import { deviceWidth } from '@/utils/deviceWidth';
import { getHeroBannerHeightCalculation } from 'src/redux/actions';
import { bannerImageClick } from '@/utils/bannerImageClick';
import { SSR_DESKTOP, SSR_MOBILE } from '@/utils/checkClientDeviceType';
import { renderStoreName } from '@/utils/renderStoreName';
import { heroBannerCTASystem } from '@/utils/heroBannerCTASystem';
import { Slider } from '@/components/ReactSlickCarousel';
import { useShopClickFunc } from '../../../../hooks/useShopClickFunc';
import { useBannerImagesSSR } from '../hooks/useBannerImagesSSR';
import BannerImageSliderNextPrevButtons from '../common/BannerImageSliderNextPrevButtons';
import { sliderSettings } from '../utils';
import { useSSRSelector } from '@/redux/ssrStore';
import Image from 'next/image';

const SliderSlides = (props) => {
  const { bannerImages, config, theme, forwardedRef } = props;
  const router = useRouter();
  const storeInfo = useSSRSelector((state) => state.storeReducer.store.store_info);
  const [shopBtnClick] = useShopClickFunc();
  const storeName = renderStoreName(deviceWidth, config) || {};

  return (
    <Slider ref={forwardedRef} {...sliderSettings}>
      {bannerImages?.map((img, idx) => {
        const rootClassNames = [
          img.is_desktop ? 'visible-desktop' : 'visible-mobile',
          img?.image_href !== '' ? 'pointer' : '',
        ];
        return (
          <HeroBannerComponent
            bannerFixedRatio={theme?.banner_fixed_ratio}
            heightCalculation={props.height}
            className={rootClassNames.join(' ')}
            key={idx}
            onClick={() => bannerImageClick(theme, img)}
          >
            <HeroBannerImage>
              {img?.image_url && (
                <Image
                  alt={`Main Banner Image ${idx + 1}`}
                  src={img.image_url}
                  className="next-banner-image"
                  objectFit="cover"
                  layout="fill"
                  objectPosition={deviceWidth > 600 ? 'center center' : 'center top'}
                  priority={idx == 0}
                />
              )}
            </HeroBannerImage>
            {idx === 0 && (storeName.logo || storeName.storeName) && (
              <HeroBannerInteractivePosition>
                <HeroBannerInteractiveBody fontColor={'#ffffff'}>
                  {storeName.logo && storeInfo?.logo_image && (
                    <HeroBannerStoreImage>
                      <Image
                        src={storeInfo?.logo_image}
                        className="next-store-image"
                        alt="store logo"
                        height="100%"
                        width="100%"
                        objectFit="cover"
                        objectPosition="center center"
                      />
                    </HeroBannerStoreImage>
                  )}
                  {storeName.storeName && (
                    <HeroBannerHeading>{storeInfo?.name}</HeroBannerHeading>
                  )}
                  {storeInfo &&
                  storeInfo?.description &&
                  storeInfo?.description.length > 164 ? (
                    <HeroBannerDescription>
                      {storeInfo?.description.substr(0, 164)}...
                      <ViewMoreSpan
                        onClick={() => {
                          router.push(getRoute(PATH.ABOUT_US, storeInfo?.domain));
                        }}
                      >
                        View More
                      </ViewMoreSpan>
                    </HeroBannerDescription>
                  ) : (
                    <HeroBannerDescription>
                      {storeInfo?.description}
                    </HeroBannerDescription>
                  )}
                  {heroBannerCTASystem(theme?.theme_class) !== '' &&
                    theme?.cta_config?.banner_cta && (
                      <HeroBannerCTAButton>
                        <HeroBannerButton
                          label={theme?.cta_config?.banner_cta}
                          backgroundColor="#ffffff"
                          fontColor="#000000"
                          roundness={theme?.roundness}
                          borderColor="#ffffff"
                          px="10px"
                          py="10px"
                          onClick={shopBtnClick}
                        />
                      </HeroBannerCTAButton>
                    )}
                </HeroBannerInteractiveBody>
              </HeroBannerInteractivePosition>
            )}
          </HeroBannerComponent>
        );
      })}
    </Slider>
  );
};
const HeroBanner1 = (props) => {
  const sliderRef = useRef();
  const { theme = null } = useSSRSelector((state) => state.storeReducer.store);

  const { bannerImageDesktop, bannerImageMobile } = useBannerImagesSSR(
    theme?.components?.Body?.find((comp) => comp?.sub_type === 'HeroBanner')
  );

  const dispatch = useDispatch();

  const [state, setState] = useState({
    isHeightFetched: false,
    heightFetched: 0,
  });

  const { heroBannerHeight } = useSelector(
    (state) => state.commonReducer.heightCalculation
  );

  useEffect(() => {
    dispatch(getHeroBannerHeightCalculation());
  }, []);

  useEffect(() => {
    if (!state.heightFetched) {
      setState((state) => ({
        ...state,
        heightFetched: heroBannerHeight,
        isHeightFetched: true,
      }));
    }
  }, [heroBannerHeight]);

  return theme ? (
    <div
      className={`heroBannerWrapper ${
        !(bannerImageDesktop?.length || bannerImageMobile?.length) ? 'placeholder' : ''
      }`}
    >
      {(bannerImageDesktop?.length > 1 || bannerImageMobile?.length > 1) && (
        <BannerImageSliderNextPrevButtons sliderRef={sliderRef} />
      )}
      {SSR_DESKTOP && (
        <SliderSlides
          forwardedRef={(slider) => (sliderRef.current = slider)}
          bannerImages={bannerImageDesktop}
          config={props.config}
          height={state.heightFetched}
          theme={theme}
        />
      )}
      {SSR_MOBILE && (
        <SliderSlides
          forwardedRef={(slider) => (sliderRef.current = slider)}
          bannerImages={bannerImageMobile}
          config={props.config}
          height={state.heightFetched}
          theme={theme}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default HeroBanner1;
